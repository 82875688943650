import React, {
    createContext,
    PropsWithChildren,
    useEffect,
    useState,
} from "react";
import Axios, { AxiosResponse } from "axios";
import { UserInterface } from "../../interfaces/interfaces";

export const myContext = createContext<Partial<UserInterface>>({});
export const Context = (props: PropsWithChildren<any>) => {
    const [user, setUser] = useState<UserInterface>();
    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_MY_API}auth/user`, {
            withCredentials: true,
        }).then((res: AxiosResponse) => {
            setUser(res.data);
        });
    }, []);
    return (
        <myContext.Provider value={user!}>{props.children}</myContext.Provider>
    );
};
