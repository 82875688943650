import React from "react";
import { motion } from "framer-motion";
import { HeroArea } from "./HeroArea";
import { TimeLine } from "./TimeLine";

const homeVariants = {
    hidden: {
        x: "100vw",
    },
    visible: {
        x: 0,
        transition: { type: "spring" },
    },
    exit: {
        x: "-100vw",
        transition: { ease: "easeInOut" },
    },
};

export const History = () => {
    return (
        <motion.div
            variants={homeVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <HeroArea />
            <TimeLine />
        </motion.div>
    );
};
