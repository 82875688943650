import {
    Box,
    Typography,
    Button,
    Grid,
    Stack,
    TextField,
    MenuItem,
    Alert,
} from "@mui/material";
import { CSSProperties, useState } from "react";
// import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { motion } from "framer-motion";
import axios, { AxiosResponse } from "axios";

const productsVariants = {
    hidden: {
        x: "100vw",
    },
    visible: {
        x: 0,
        transition: { type: "spring" },
    },
    exit: {
        x: "-100vw",
        transition: { ease: "easeInOut" },
    },
};

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    post: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "2rem",
        alignItems: "flex-start",
        padding: "4%",
        minHeight: "70vh",
        width: "90%",
    },
    container: {
        textAlign: "center",
        width: "100%",
        backgroundColor: "#eee",
        minHeight: "100vh",
        paddingTop: "25vh",
    },
    paragraphe: {
        textAlign: "justify",
        textJustify: "inter-word",
        color: "#121212",
    },
    btn: {
        backgroundColor: "#3FB7BD",
        borderRadius: "20px",
        color: "#fff",
    },
    title: {
        color: "#3FB7BD",
        fontWeight: "bold",
    },
};

export const Contact = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [order, setOrder] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const onSubmit = async (e: any) => {
        e.preventDefault();
        if (!name || !email || !phone || !order) {
            setError(true);
        } else {
            await axios
                .post(
                    `${process.env.REACT_APP_MY_API}order`,
                    {
                        name,
                        email,
                        phone,
                        order,
                    },
                    { withCredentials: true }
                )
                .then((res: AxiosResponse) => {
                    if (res.status === 201) {
                        setName("");
                        setEmail("");
                        setPhone("");
                        setOrder("");
                        setSuccess(true);
                    }
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    };
    return (
        <motion.div
            variants={productsVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <Grid
                style={styles.container}
                container
                rowSpacing={1}
                columnSpacing={0}
            >
                <Grid item xs={12} sm={6}>
                    <Box style={styles.post}>
                        <Typography style={styles.title} variant="h4">
                            Contactez Notre Equipe de vente
                        </Typography>
                        <Typography style={styles.paragraphe}>
                            Voulez-vous en savoir plus sur NatFeed naturel?
                            <br />
                            Veuillez remplir ce formulaire et nous vous
                            contecterons dans les plus brefs délais que
                            possible.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <form onSubmit={onSubmit}>
                        <Stack
                            style={styles.post}
                            spacing={2}
                            direction="column"
                        >
                            {error ? (
                                <Alert
                                    severity="error"
                                    onClose={() => {
                                        setError(false);
                                    }}
                                >
                                    All Field are Required
                                </Alert>
                            ) : null}{" "}
                            {success ? (
                                <Alert
                                    severity="success"
                                    onClose={() => {
                                        setSuccess(false);
                                    }}
                                >
                                    Thanks for Contacting us we will respond
                                    ASAP
                                </Alert>
                            ) : null}
                            <TextField
                                label="Name"
                                size="small"
                                required
                                fullWidth
                                color="secondary"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                label="Email"
                                size="small"
                                type="email"
                                required
                                fullWidth
                                color="secondary"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                label="Téléphone"
                                size="small"
                                required
                                fullWidth
                                color="secondary"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            <TextField
                                label="Demande"
                                select
                                required
                                fullWidth
                                size="small"
                                color="secondary"
                                value={order}
                                onChange={(e) => setOrder(e.target.value)}
                            >
                                <MenuItem value="NatFertil">NatFertil</MenuItem>
                                <MenuItem value="NatFeed (Aquaculture)">
                                    NatFeed (Aquaculture)
                                </MenuItem>
                                <MenuItem value="NatFeed (Aquaculture)">
                                    NatFeed (Pets)
                                </MenuItem>
                                <MenuItem value="NatFeed (Poultry)">
                                    NatFeed (Poultry)
                                </MenuItem>
                            </TextField>
                            <Button
                                variant="contained"
                                type="submit"
                                endIcon={<ArrowForwardIosIcon />}
                                style={styles.btn}
                            >
                                Envoyer
                            </Button>
                        </Stack>
                    </form>
                </Grid>
            </Grid>
        </motion.div>
    );
};
