import Vid from "../../assets/video.mp4";
import { CSSProperties } from "react";
import { Box } from "@mui/material";

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    ContentContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: "50vh",
        overflow: "hidden",
    },
};

export const Video = () => {
    return (
        <Box style={styles.ContentContainer}>
            <video
                width="100%"
                height="100%"
                muted
                autoPlay={true}
                loop
                preload="auto"
                style={{ pointerEvents: "none" }}
            >
                <source src={Vid} type="video/mp4" />
            </video>
        </Box>
    );
};
