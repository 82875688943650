import { Grid, Box, Typography } from "@mui/material";
import ADN from "../../assets/HighlightedContent/adn.jpg";
import RD from "../../assets/HighlightedContent/rd.jpg";
import TEAM from "../../assets/HighlightedContent/team.jpg";
import PRODUCTS from "../../assets/HighlightedContent/products.jpg";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 1 },
    },
    hidden: { opacity: 0, y: -100 },
};

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    ContentContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "4%",
    },
    image: {
        position: "relative",
        overflow: "hidden",
    },
    image__img: {
        display: "block",
        width: "100%",
    },
    image__title: {
        fontWeight: "bold",
    },
    image__description: {
        margin: "0.25em 5%",
    },
};

export const HighlightedContent = () => {
    const { t } = useTranslation();
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return (
        <motion.div
            ref={ref}
            variants={boxVariant}
            initial="hidden"
            animate={control}
        >
            <Grid
                style={styles.ContentContainer}
                container
                rowSpacing={6}
                columnSpacing={6}
            >
                <Grid item xs={12} sm={6} lg={3}>
                    <Box
                        style={styles.image}
                        sx={{
                            ":hover > *": {
                                opacity: "1",
                                transform: "translateY(0)",
                                transition: "all 1s ease-in-out",
                            },
                            ":hover > #cube-1": {
                                transform: "scale(1.2)",
                                transition: "transform 5s ease-in-out",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                transition: "transform 1s ease-in-out",
                            }}
                            id="cube-1"
                        >
                            <img
                                style={styles.image__img}
                                src={ADN}
                                alt="Content"
                                loading="lazy"
                            />
                        </Box>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                background: "rgba(0, 0, 0, 0.6)",
                                color: "#ffffff",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                opacity: "0",
                                transition: "opacity 1s ease-in-out",
                                backdropFilter: "blur(0px)",
                                ":hover": {
                                    backdropFilter: "blur(3px)",
                                    transition:
                                        "backdrop-filter 5s ease-in-out",
                                },
                                ":hover > *": {
                                    opacity: "1",
                                    transform: "translateY(0)",
                                    transition: "all 1s ease-in-out",
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    transform: "translateY(40px)",
                                    transition: "transform 1s ease-in-out",
                                }}
                                variant="h6"
                                style={styles.image__title}
                            >
                                {t("highlightedContent.1.title")}
                            </Typography>
                            <Typography
                                sx={{
                                    transform: "translateY(40px)",
                                    transition: "transform 1s ease-in-out",
                                }}
                                variant="body2"
                                style={styles.image__description}
                            >
                                {t("highlightedContent.1.subtitle")}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Box
                        style={styles.image}
                        sx={{
                            ":hover > *": {
                                opacity: "1",
                                transform: "translateY(0)",
                                transition: "all 1s ease-in-out",
                            },
                            ":hover > #cube-2": {
                                transform: "scale(1.2)",
                                transition: "transform 5s ease-in-out",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                transition: "transform 1s ease-in-out",
                            }}
                            id="cube-2"
                        >
                            <img
                                style={styles.image__img}
                                src={TEAM}
                                alt="Content"
                                loading="lazy"
                            />
                        </Box>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                background: "rgba(0, 0, 0, 0.6)",
                                color: "#ffffff",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                opacity: "0",
                                transition: "opacity 1s ease-in-out",
                                backdropFilter: "blur(0px)",
                                ":hover": {
                                    backdropFilter: "blur(3px)",
                                    transition:
                                        "backdrop-filter 5s ease-in-out",
                                },
                                ":hover > *": {
                                    opacity: "1",
                                    transform: "translateY(0)",
                                    transition: "all 1s ease-in-out",
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    transform: "translateY(40px)",
                                    transition: "transform 1s ease-in-out",
                                }}
                                variant="h6"
                                style={styles.image__title}
                            >
                                {t("highlightedContent.2.title")}
                            </Typography>
                            <Typography
                                sx={{
                                    transform: "translateY(40px)",
                                    transition: "transform 1s ease-in-out",
                                }}
                                variant="body2"
                                style={styles.image__description}
                            >
                                {t("highlightedContent.2.subtitle")}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Box
                        style={styles.image}
                        sx={{
                            ":hover > *": {
                                opacity: "1",
                                transform: "translateY(0)",
                                transition: "all 1s ease-in-out",
                            },
                            ":hover > #cube-3": {
                                transform: "scale(1.2)",
                                transition: "transform 5s ease-in-out",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                transition: "transform 1s ease-in-out",
                            }}
                            id="cube-3"
                        >
                            <img
                                style={styles.image__img}
                                src={RD}
                                alt="Content"
                                loading="lazy"
                            />
                        </Box>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                background: "rgba(0, 0, 0, 0.6)",
                                color: "#ffffff",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                opacity: "0",
                                transition: "opacity 1s ease-in-out",
                                backdropFilter: "blur(0px)",
                                ":hover": {
                                    backdropFilter: "blur(3px)",
                                    transition:
                                        "backdrop-filter 5s ease-in-out",
                                },
                                ":hover > *": {
                                    opacity: "1",
                                    transform: "translateY(0)",
                                    transition: "all 1s ease-in-out",
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    transform: "translateY(40px)",
                                    transition: "transform 1s ease-in-out",
                                }}
                                variant="h6"
                                style={styles.image__title}
                            >
                                {t("highlightedContent.3.title")}
                            </Typography>
                            <Typography
                                sx={{
                                    transform: "translateY(40px)",
                                    transition: "transform 1s ease-in-out",
                                }}
                                variant="body2"
                                style={styles.image__description}
                            >
                                {t("highlightedContent.3.subtitle")}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Box
                        style={styles.image}
                        sx={{
                            ":hover > *": {
                                opacity: "1",
                                transform: "translateY(0)",
                                transition: "all 1s ease-in-out",
                            },
                            ":hover > #cube-4": {
                                transform: "scale(1.2)",
                                transition: "transform 5s ease-in-out",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                transition: "transform 1s ease-in-out",
                            }}
                            id="cube-4"
                        >
                            <img
                                style={styles.image__img}
                                src={PRODUCTS}
                                alt="Content"
                                loading="lazy"
                            />
                        </Box>
                        <Box
                            sx={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                width: "100%",
                                height: "100%",
                                background: "rgba(0, 0, 0, 0.6)",
                                color: "#ffffff",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                opacity: "0",
                                transition: "opacity 1s ease-in-out",
                                backdropFilter: "blur(0px)",
                                ":hover": {
                                    backdropFilter: "blur(3px)",
                                    transition:
                                        "backdrop-filter 5s ease-in-out",
                                },
                                ":hover > *": {
                                    opacity: "1",
                                    transform: "translateY(0)",
                                    transition: "all 1s ease-in-out",
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    transform: "translateY(40px)",
                                    transition: "transform 1s ease-in-out",
                                }}
                                variant="h6"
                                style={styles.image__title}
                            >
                                {t("highlightedContent.4.title")}
                            </Typography>
                            <Typography
                                sx={{
                                    transform: "translateY(40px)",
                                    transition: "transform 1s ease-in-out",
                                }}
                                variant="body2"
                                style={styles.image__description}
                            >
                                {t("highlightedContent.4.subtitle")}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </motion.div>
    );
};
