import { Stack, Typography, Box, Grid } from "@mui/material";
import colors from "../../assets/colors";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import Image from "../../assets/History/Home.jpeg";

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    entomo: {
        color: colors.secondary,
    },
    nutris: {
        color: colors.primary,
    },
    container: {
        padding: "25vh 4% 0%",
        display: "flex",
        backgroundColor: "#eee",
        height: "75vh  ",
        justifyContent: "center",
        alignItems: "space-between",
    },
    paragraph: {
        textAlign: "justify",
        textJustify: "inter-word",
        color: "#121212",
    },
    title: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    image: {
        width: "100%",
        borderRadius: "10%",
        marginLeft: "2%",
        border: "5px solid white",
    },
};

export const HeroArea = () => {
    const { t } = useTranslation();
    return (
        <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            style={styles.container}
        >
            <Grid
                style={styles.posts}
                container
                rowSpacing={1}
                columnSpacing={0}
                // my={1}
                // mx={4}
            >
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography variant="h4" style={styles.title}>
                            <b>
                                <span style={styles.entomo}>
                                    {t("history.title1")}
                                </span>{" "}
                                <span style={styles.nutris}>
                                    {t("history.title2")}
                                </span>
                            </b>
                        </Typography>
                        <Typography variant="body1" style={styles.paragraph}>
                            {t("history.paragraph1")}
                            <br />
                            <br />
                            <b>
                                <span style={styles.entomo}>Entomo</span>
                                <span style={styles.nutris}>Nutris</span>
                            </b>{" "}
                            {t("history.paragraph2")}
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: { xs: "none", md: "flex" },
                    }}
                >
                    <Box>
                        <img src={Image} alt="history" style={styles.image} />
                    </Box>
                </Grid>
            </Grid>
        </Stack>
    );
};
