import {
    Stack,
    Button,
    IconButton,
    Link,
    Typography,
    Snackbar,
    Alert,
    AlertProps,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { CSSProperties, useState, useEffect, forwardRef } from "react";
import { Gb, Fr } from "react-flags-select";
import i18n from "i18next";
import cookies from "js-cookie";

interface StylesDictionary {
    [Key: string]: CSSProperties;
}
const styles: StylesDictionary = {
    TopNavBar: {
        backgroundColor: "#eee",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "fixed",
        width: "95%",
        zIndex: "1000",
        height: "42px",
        paddingRight: "80px",
    },
    btn: {
        borderRadius: "15px",
        color: "#121212",
    },
};

const SnackbarAlert = forwardRef<HTMLDivElement, AlertProps>(
    function SnackbarAlert(props, ref) {
        return <Alert elevation={6} ref={ref} {...props} />;
    }
);

export const TopNavBar = () => {
    const currentLanguageCode = cookies.get("i18next") || "fr";
    const [language, setLanguage] = useState(currentLanguageCode);
    const [show, setShow] = useState(true);
    const [open, setOpen] = useState(false);
    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    const controlNavbar = () => {
        if (window.scrollY < window.innerHeight - 94) {
            setShow(true);
        } else {
            setShow(false);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        return () => {
            window.removeEventListener("scroll", controlNavbar);
        };
    }, []);
    return (
        <Stack
            direction="row"
            spacing={0.5}
            style={styles.TopNavBar}
            sx={{
                opacity: `${show ? "1" : "0"}`,
                transition: "opacity 0.5s ease-in",
            }}
        >
            {language !== "fr" ? (
                <Button
                    style={styles.btn}
                    variant="text"
                    size="small"
                    startIcon={<Fr style={{ borderRadius: "6px" }} />}
                    disableElevation
                    disableRipple
                    sx={{
                        ":hover": {
                            backgroundColor: "transparent",
                        },
                    }}
                    onClick={() => {
                        i18n.changeLanguage("fr");
                        setLanguage("fr");
                        setOpen(true);
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        fontWeight="300"
                        sx={{ textTransform: "none" }}
                    >
                        Français
                    </Typography>
                </Button>
            ) : null}
            {language !== "en" ? (
                <Button
                    style={styles.btn}
                    variant="text"
                    size="small"
                    startIcon={<Gb style={{ borderRadius: "6px" }} />}
                    disableElevation
                    disableRipple
                    sx={{
                        ":hover": {
                            backgroundColor: "transparent",
                        },
                    }}
                    onClick={() => {
                        i18n.changeLanguage("en");
                        setLanguage("en");
                        setOpen(true);
                    }}
                >
                    <Typography
                        fontWeight="300"
                        variant="subtitle2"
                        sx={{ textTransform: "none" }}
                    >
                        English
                    </Typography>
                </Button>
            ) : null}

            <Link
                href="https://www.linkedin.com/company/entomonutris/"
                target="_blank"
            >
                <IconButton
                    aria-label="linkedin"
                    size="small"
                    sx={{
                        borderRadius: "15px",
                        color: "#121212",
                        ":hover": {
                            backgroundColor: "transparent",
                            color: "#0072b1",
                            transition: "color 0.2s",
                        },
                    }}
                >
                    <LinkedInIcon fontSize="small" />
                </IconButton>
            </Link>
            <Link href="https://twitter.com/entomonutris" target="_blank">
                <IconButton
                    aria-label="twitter"
                    size="small"
                    sx={{
                        borderRadius: "15px",
                        color: "#121212",
                        ":hover": {
                            backgroundColor: "transparent",
                            color: "#1DA1F2",
                            transition: "color 0.2s",
                        },
                    }}
                >
                    <TwitterIcon fontSize="small" />
                </IconButton>
            </Link>

            <Link href="https://www.youtube.com/" target="_blank">
                <IconButton
                    aria-label="youtube"
                    size="small"
                    sx={{
                        borderRadius: "15px",
                        color: "#121212",
                        ":hover": {
                            backgroundColor: "transparent",
                            color: "#FF0000",
                            transition: "color 0.2s",
                        },
                    }}
                >
                    <YouTubeIcon fontSize="small" />
                </IconButton>
            </Link>
            <Snackbar
                open={open}
                autoHideDuration={3500}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <SnackbarAlert severity="success">
                    {language !== "fr"
                        ? "La langue a été changée en Français avec succès"
                        : "The language was successfully changed to English"}
                </SnackbarAlert>
            </Snackbar>
        </Stack>
    );
};
