import { Typography, Stack } from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Image1 from "../../assets/Home-1.jpg";
import Image2 from "../../assets/Home-2.jpg";
import Image3 from "../../assets/Home-3.jpg";
import cookies from "js-cookie";
import "./HeroArea.css";

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    HeroAreaContainer: {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Image1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        objectFit: "cover",
        objectPosition: "center",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    HeroAreaContent: {
        color: "white",
        position: "fixed",
        textAlign: "center",
    },
};

const titleVariants = {
    hidden: {
        y: "50vh",
        opacity: 0,
        scale: 0.25,
    },
    visible: {
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { delay: 1, type: "tween", duration: 1 },
    },
};

const contentVariants = {
    hidden: {
        y: "50vh",
        opacity: 0,
        scale: 0.25,
    },
    visible: {
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { delay: 2, type: "tween", duration: 1 },
    },
};

export const HeroArea = () => {
    const currentLanguageCode = cookies.get("i18next") || "fr";
    let i = 1;
    let op = 1;
    const diapo = () => {
        const box = document.getElementById("hero-area");
        const title = document.getElementById("title");
        const content = document.getElementById("content");
        setTimeout(diapo, 10);
        op += 0.05;
        if (op > 20) {
            op = 0.5;
            i++;
            if (i > 3) {
                i = 1;
            }
            if (i === 1) {
                if (box !== undefined) {
                    box!.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Image1})`;
                }
                if (title !== undefined) {
                    title!.innerHTML = "Agriculture";
                }
                if (content !== undefined) {
                    if (currentLanguageCode === "fr") {
                        content!.innerHTML =
                            "Fertilisant, Amendement, Biostimulant <br />NatFertil 2-6-4 <sup>&reg;</sup>";
                    } else {
                        content!.innerHTML =
                            "Fertilizer, Amendment, Biostimulant <br />NatFertil 2-6-4 <sup>&reg;</sup>";
                    }
                }
            } else if (i === 2) {
                if (box !== undefined) {
                    box!.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Image2})`;
                }
                if (title !== undefined) {
                    if (currentLanguageCode === "fr") {
                        title!.innerHTML = "Nutrition Animale";
                    } else {
                        title!.innerHTML = "Animal Nutrition";
                    }
                }
                if (content !== undefined) {
                    if (currentLanguageCode === "fr") {
                        content!.innerHTML =
                            "Aquaculture<br/> Volaille<br/>nourriture pour animaux domestique";
                    } else {
                        content!.innerHTML =
                            "Aquaculture<br/> Poultry<br/>Pet food";
                    }
                }
            } else if (i === 3) {
                if (box !== undefined) {
                    box!.style.backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Image3})`;
                }
                if (title !== undefined) {
                    if (currentLanguageCode === "fr") {
                        title!.innerHTML = "Pour les industriels BSF";
                    } else {
                        title!.innerHTML = "For BSF industries";
                    }
                }
                if (content !== undefined) {
                    if (currentLanguageCode === "fr") {
                        content!.innerHTML =
                            "Larves de cinq jours prét à l'usage";
                    } else {
                        content!.innerHTML = "5 DOL or Ready to use larvae";
                    }
                }
            }
            console.log("i", i);
        }
        if (box !== undefined) {
            box!.style.opacity = op + "";
        }
    };
    useEffect(() => {
        window.onload = () => {
            diapo();
        };
    });
    const [show, setShow] = useState(true);
    const controlNavbar = () => {
        if (window.scrollY < window.innerHeight / 4) {
            setShow(true);
        } else {
            setShow(false);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        return () => {
            window.removeEventListener("scroll", controlNavbar);
        };
    }, []);
    return (
        <Stack
            id="hero-area"
            direction="column"
            style={styles.HeroAreaContainer}
            className="cover"
            spacing={23}
        >
            <motion.div
                variants={titleVariants}
                initial="hidden"
                animate="visible"
                style={styles.HeroAreaContent}
            >
                <Typography
                    id="title"
                    variant="h3"
                    sx={{
                        opacity: `${show ? "1" : "0"}`,
                        transition: "opacity 0.2s ease-in",
                    }}
                >
                    Agriculture
                </Typography>
            </motion.div>
            <motion.div
                variants={contentVariants}
                initial="hidden"
                animate="visible"
                style={styles.HeroAreaContent}
            >
                <Typography
                    sx={{
                        opacity: `${show ? "1" : "0"}`,
                        transition: "opacity 0.4s ease-in",
                    }}
                    id="content"
                    variant="h5"
                >
                    {currentLanguageCode === "fr"
                        ? "Fertilisant, Amendement, Biostimulant"
                        : "Fertilizer, Amendment, Biostimulant"}
                    <br />
                    NatFertil 2-6-4 <sup>&reg;</sup>
                </Typography>
            </motion.div>
        </Stack>
    );
};
