import React from "react";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import Image1 from "../../assets/Presentation/1.png";
import Image2 from "../../assets/Presentation/2.png";
import Image3 from "../../assets/Presentation/3.png";

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    container: {
        color: "#121212",
        padding: "25vh 4% 0%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
    },
    ContentContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    ContentContainer2: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "space-between",
        gap: "3rem",
        minHeight: "50vh",
    },
    img: {
        maxWidth: "100%",
    },
    img2: {
        maxWidth: "100%",
        maxHeight: "50vh",
    },
    img3: {
        width: "100%",
        maxHeight: "90vh",
    },
    title: {
        justifySelf: "flex-start",
        alignSelf: "flex-start",
    },
};
const presentationVariants = {
    hidden: {
        x: "100vw",
    },
    visible: {
        x: 0,
        transition: { type: "spring" },
    },
    exit: {
        x: "-100vw",
        transition: { ease: "easeInOut" },
    },
};

export const Presentation = () => {
    const { t } = useTranslation();
    return (
        <motion.div
            variants={presentationVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            style={styles.container}
        >
            <Box style={styles.ContentContainer}>
                <h3 style={styles.title}>{t("presentation.title.1")}</h3>
                <p>{t("presentation.paragraph.1")}</p>
                <img src={Image1} style={styles.img} alt="Insects" />
            </Box>
            <Box style={styles.ContentContainer2}>
                <div>
                    <h3 style={styles.title}>{t("presentation.title.2")}</h3>
                    <ul>
                        <li>{t("presentation.paragraph.2.1")}</li>
                        <br />
                        <li>{t("presentation.paragraph.2.2")}</li>
                        <br />
                        <li>{t("presentation.paragraph.2.3")}</li>
                        <br />
                        <li>{t("presentation.paragraph.2.4")}</li>
                    </ul>
                </div>
                <Box
                    sx={{
                        display: { xs: "none", sm: "block" },
                    }}
                >
                    <img src={Image2} style={styles.img2} alt="Insects" />
                </Box>
            </Box>
            <Box style={styles.ContentContainer}>
                <img src={Image3} style={styles.img3} alt="Insects" />
                <h3 style={styles.title}>{t("presentation.title.3")}</h3>
                <ul style={styles.title}>
                    <li>{t("presentation.paragraph.3.1")}</li>
                    <br />
                    <li>{t("presentation.paragraph.3.2")}</li>
                    <br />
                    <li>{t("presentation.paragraph.3.3")}</li>
                </ul>
            </Box>
        </motion.div>
    );
};
