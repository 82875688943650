import React from "react";
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineOppositeContent,
} from "@mui/lab";
import { Stack, Typography, Box, Grid } from "@mui/material";
import colors from "../../assets/colors";
import { CSSProperties } from "react";
import Part1 from "../../assets/Partners/1.png";
import Part2 from "../../assets/Partners/2.png";
import Part3 from "../../assets/Partners/3.png";
import Part4 from "../../assets/Partners/4.png";
import Part5 from "../../assets/Partners/5.png";
import Part6 from "../../assets/Partners/6.png";
import Part7 from "../../assets/Partners/7.png";
import Part8 from "../../assets/Partners/8.png";
import Part9 from "../../assets/Partners/9.png";
import Part10 from "../../assets/Partners/10.png";
import Part11 from "../../assets/Partners/11.png";
import Part12 from "../../assets/Partners/12.png";
import Part13 from "../../assets/Partners/13.png";
import Part14 from "../../assets/Partners/14.jpeg";
import { useTranslation } from "react-i18next";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    entomo: {
        color: colors.secondary,
    },
    nutris: {
        color: colors.primary,
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "2%",
    },
    timelineContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "1%",
    },
    paragraph: {
        textAlign: "justify",
        textJustify: "inter-word",
    },
    title: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    historyImg: {
        height: "auto",
        width: "200px",
        marginLeft: "5%",
        borderRadius: "20%",
    },
    posts: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "4%",
    },

    post: {
        height: "70%",
        width: "70%",
    },
};

export const TimeLine = () => {
    const { t } = useTranslation();
    return (
        <Stack style={styles.container}>
            <Timeline>
                <TimelineItem>
                    <TimelineOppositeContent
                        style={{ flex: 0.1, padding: "1%" }}
                        color="text.secondary"
                    ></TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color="secondary">
                            <EmojiObjectsIcon />
                        </TimelineDot>
                        <TimelineConnector sx={{ bgcolor: colors.primary }} />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Stack direction="row" style={styles.timelineContainer}>
                            <Box>
                                <Typography variant="h6">
                                    2020
                                    <br />
                                    {t("history.timeline.title1")}
                                    <span style={styles.entomo}>Entomo</span>
                                    <span style={styles.nutris}>Nutris</span>
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    style={styles.paragraph}
                                >
                                    {t("history.timeline.body1")}
                                </Typography>
                            </Box>
                        </Stack>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        style={{ flex: 0.1, padding: "1%" }}
                        color="text.secondary"
                    ></TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot sx={{ bgcolor: colors.primary }}>
                            <LocationCityIcon />
                        </TimelineDot>
                        <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Stack direction="row" style={styles.timelineContainer}>
                            <Box>
                                <Typography variant="h6">
                                    2021
                                    <br />
                                    {t("history.timeline.title2")}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    style={styles.paragraph}
                                >
                                    {t("history.timeline.body2")}
                                </Typography>
                            </Box>
                        </Stack>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        style={{ flex: 0.1, padding: "1%" }}
                        color="text.secondary"
                    ></TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color="secondary">
                            <LocalFloristIcon />
                        </TimelineDot>
                        <TimelineConnector sx={{ bgcolor: colors.primary }} />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Stack direction="row" style={styles.timelineContainer}>
                            <Box>
                                <Typography variant="h6">
                                    2022
                                    <br />
                                    {t("history.timeline.title3")}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    style={styles.paragraph}
                                >
                                    {t("history.timeline.body3")}
                                </Typography>
                            </Box>
                        </Stack>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        style={{ flex: 0.1, padding: "1%" }}
                        color="text.secondary"
                    ></TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color="primary">
                            <WorkspacePremiumIcon sx={{ color: "white" }} />
                        </TimelineDot>
                        <TimelineConnector sx={{ bgcolor: colors.secondary }} />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Stack direction="row" style={styles.timelineContainer}>
                            <Box>
                                <Typography variant="h6">
                                    {t("history.timeline.title5")}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    style={styles.paragraph}
                                >
                                    <Grid
                                        style={styles.posts}
                                        container
                                        rowSpacing={1}
                                        columnSpacing={0}
                                        // my={1}
                                        // mx={4}
                                    >
                                        <Grid item xs={6} sm={4}>
                                            <Box>
                                                <img
                                                    style={styles.post}
                                                    src={Part9}
                                                    alt="post"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Box>
                                                <img
                                                    style={styles.post}
                                                    src={Part7}
                                                    alt="post"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Box>
                                                <img
                                                    style={styles.post}
                                                    src={Part12}
                                                    alt="post"
                                                />
                                            </Box>
                                        </Grid>
                                        <br />
                                        <br />
                                        <br />
                                        <Grid item xs={12}>
                                            <Box>
                                                <ul>
                                                    <li>
                                                        {t(
                                                            "history.timeline.body5"
                                                        )}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        {t(
                                                            "history.timeline.body5.2"
                                                        )}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        {t(
                                                            "history.timeline.body5.3"
                                                        )}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        {t(
                                                            "history.timeline.body5.4"
                                                        )}
                                                    </li>
                                                </ul>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Box>
                        </Stack>
                    </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                    <TimelineOppositeContent
                        style={{ flex: 0.1, padding: "1%" }}
                        color="text.secondary"
                    ></TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot sx={{ bgcolor: colors.secondary }}>
                            <HandshakeIcon />
                        </TimelineDot>
                        <TimelineConnector sx={{ bgcolor: colors.primary }} />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Stack direction="row" style={styles.timelineContainer}>
                            <Box>
                                <Typography variant="h6">
                                    {t("history.timeline.title4")}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    style={styles.paragraph}
                                >
                                    <Grid
                                        style={styles.posts}
                                        container
                                        rowSpacing={1}
                                        columnSpacing={0}
                                        // my={1}
                                        // mx={4}
                                    >
                                        <Grid item xs={6} sm={4} lg={3}>
                                            <Box>
                                                <img
                                                    style={styles.post}
                                                    src={Part1}
                                                    alt="post"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={3}>
                                            <Box>
                                                <img
                                                    style={styles.post}
                                                    src={Part2}
                                                    alt="post"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={3}>
                                            <Box>
                                                <img
                                                    style={styles.post}
                                                    src={Part3}
                                                    alt="post"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={4} lg={3}>
                                            <Box>
                                                <img
                                                    style={styles.post}
                                                    src={Part4}
                                                    alt="post"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={3} lg={3}>
                                            <img
                                                style={styles.post}
                                                src={Part5}
                                                alt="post"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} lg={3}>
                                            <img
                                                style={styles.post}
                                                src={Part6}
                                                alt="post"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} lg={3}>
                                            <img
                                                style={styles.post}
                                                src={Part7}
                                                alt="post"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} lg={3}>
                                            <img
                                                style={styles.post}
                                                src={Part8}
                                                alt="post"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} lg={3}>
                                            <img
                                                style={styles.post}
                                                src={Part9}
                                                alt="post"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} lg={3}>
                                            <img
                                                style={styles.post}
                                                src={Part10}
                                                alt="post"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} lg={3}>
                                            <img
                                                style={styles.post}
                                                src={Part11}
                                                alt="post"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} lg={3}>
                                            <img
                                                style={styles.post}
                                                src={Part12}
                                                alt="post"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} lg={3}>
                                            <img
                                                style={styles.post}
                                                src={Part13}
                                                alt="post"
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} lg={3}>
                                            <img
                                                style={styles.post}
                                                src={Part14}
                                                alt="post"
                                            />
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </Box>
                        </Stack>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </Stack>
    );
};
