import { Box, Typography, Grid } from "@mui/material";
import colors from "../../assets/colors";
import Post1 from "../../assets/Testimonials/post-1.png";
import Post2 from "../../assets/Testimonials/post-2.png";
import Post3 from "../../assets/Testimonials/post-3.png";
import Post4 from "../../assets/Testimonials/post-4.png";
import Post5 from "../../assets/Testimonials/post-5.png";
import Post6 from "../../assets/Testimonials/post-6.png";
import Post7 from "../../assets/Testimonials/post-7.png";
import Post8 from "../../assets/Testimonials/post-8.png";
import Post9 from "../../assets/Testimonials/post-9.png";
import Post10 from "../../assets/Testimonials/post-10.png";
import Post11 from "../../assets/Testimonials/post-11.png";
import Post12 from "../../assets/Testimonials/post-12.png";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const boxVariant = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 1 },
    },
    hidden: { opacity: 0, y: -100 },
};

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    entomo: {
        color: colors.secondary,
    },
    nutris: {
        color: colors.primary,
    },
    posts: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "4%",
    },
    container: {
        padding: "4%",
        textAlign: "center",
    },
    post: {
        height: "50%",
        width: "50%",
    },
    paragraphe: {
        textAlign: "justify",
        textJustify: "inter-word",
    },
};
export const Posts = () => {
    const { t } = useTranslation();
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return (
        <Box style={styles.container}>
            <Typography variant="h4">
                {t("posts.title")}
                <span style={styles.entomo}>Entomo</span>
                <span style={styles.nutris}>Nutris</span>
            </Typography>
            <motion.div
                ref={ref}
                variants={boxVariant}
                initial="hidden"
                animate={control}
            >
                <Grid
                    style={styles.posts}
                    container
                    rowSpacing={1}
                    columnSpacing={0}
                    // my={1}
                    // mx={4}
                >
                    <Grid item xs={6} sm={4} lg={2}>
                        <Box>
                            <img style={styles.post} src={Post1} alt="post" />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} lg={2}>
                        <Box>
                            <img style={styles.post} src={Post2} alt="post" />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} lg={2}>
                        <Box>
                            <img style={styles.post} src={Post3} alt="post" />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} lg={2}>
                        <Box>
                            <img style={styles.post} src={Post4} alt="post" />
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}>
                        <img style={styles.post} src={Post5} alt="post" />
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}>
                        <img style={styles.post} src={Post6} alt="post" />
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}>
                        <img style={styles.post} src={Post7} alt="post" />
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}>
                        <img style={styles.post} src={Post8} alt="post" />
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}>
                        <img style={styles.post} src={Post9} alt="post" />
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}>
                        <img style={styles.post} src={Post10} alt="post" />
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}>
                        <img style={styles.post} src={Post11} alt="post" />
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}>
                        <img style={styles.post} src={Post12} alt="post" />
                    </Grid>
                </Grid>

                <Typography style={styles.paragraphe}>
                    {t("posts.body")}
                </Typography>
            </motion.div>
        </Box>
    );
};
