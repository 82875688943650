import { Typography, Box, Stack, Divider, Paper } from "@mui/material";
import colors from "../../assets/colors";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    entomo: {
        color: colors.secondary,
    },
    nutris: {
        color: colors.primary,
    },
    ContentContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
};

const boxVariant = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 1 },
    },
    hidden: { opacity: 0, y: -100 },
};

export const Definition = () => {
    const { t } = useTranslation();
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return (
        <Paper sx={{ padding: "4%" }} elevation={60}>
            <Stack
                direction="column"
                spacing={4}
                divider={<Divider orientation="horizontal" flexItem />}
                style={styles.ContentContainer}
            >
                <Box>
                    <Typography variant="h4">
                        <span style={styles.entomo}>Entomo</span>
                        <span style={styles.nutris}>Nutris</span>{" "}
                        {t("definition.title")} Agritech
                    </Typography>
                </Box>
                <motion.div
                    ref={ref}
                    variants={boxVariant}
                    initial="hidden"
                    animate={control}
                >
                    <Box>
                        <Typography variant="h6">
                            {t("definition.body.1")}&nbsp;
                            <span style={styles.entomo}>Entomo</span>
                            <span style={styles.nutris}>Nutris</span>&nbsp;
                            {t("definition.body.2")}
                        </Typography>
                    </Box>
                </motion.div>
            </Stack>
        </Paper>
    );
};
