import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { CSSProperties } from "react";

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    container: {
        padding: "25vh 4% 0%",
        display: "flex",
        backgroundColor: "#fff",
        height: "70vh  ",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
};

const notFoundVariants = {
    hidden: {
        x: "100vw",
    },
    visible: {
        x: 0,
        transition: { type: "spring" },
    },
    exit: {
        x: "-100vw",
        transition: { ease: "easeInOut" },
    },
};

export const NotFound = () => {
    return (
        <motion.div
            variants={notFoundVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            style={styles.container}
        >
            <h1>404</h1>
            <h3>Sorry That page can not be found</h3>
            <Link to="/">
                <Button variant="contained">Back to homepage ...</Button>
            </Link>

            <Typography variant="subtitle2">but i'm not sorry</Typography>
        </motion.div>
    );
};
