import { Box, Typography, Button, Grid } from "@mui/material";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import Image from "././../../assets/Aquaculture/Side.png";
import Product from "././../../assets/Aquaculture/Product.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const productsVariants = {
    hidden: {
        x: "100vw",
    },
    visible: {
        x: 0,
        transition: { type: "spring" },
    },
    exit: {
        x: "-100vw",
        transition: { ease: "easeInOut" },
    },
};

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    post: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
        padding: "4%",
    },
    container: {
        textAlign: "center",
        width: "100%",
        backgroundColor: "#eee",
        display: "flex",
        paddingTop: "25vh",
        minHeight: "100vh  ",
    },
    paragraphe: {
        textAlign: "justify",
        textJustify: "inter-word",
        color: "#121212",
    },
    side: {
        width: "100%",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${Image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        objectFit: "cover",
        objectPosition: "center",
        minHeight: "100vh",
    },
    image: {
        height: "65vh",
    },
    btn: {
        backgroundColor: "#3FB7BD",
        borderRadius: "20px",
        color: "#fff",
    },
};

export const Aquaculture = () => {
    const { t } = useTranslation();
    return (
        <motion.div
            variants={productsVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <Grid
                style={styles.container}
                container
                rowSpacing={1}
                columnSpacing={0}
                // my={1}
                // mx={4}
            >
                <Grid item xs={12} sm={4} style={styles.side}></Grid>
                <Grid item xs={12} sm={4}>
                    <Box style={styles.post}>
                        <h3>Aquaculture</h3>
                        <Typography style={styles.paragraphe}>
                            {t("aquaculture.paragraph")}
                        </Typography>
                        <Link
                            to="/products/contact"
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                variant="contained"
                                endIcon={<ArrowForwardIosIcon />}
                                style={styles.btn}
                            >
                                Contact
                            </Button>
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box style={styles.post}>
                        <img src={Product} alt="aqua" style={styles.image} />
                    </Box>
                </Grid>
            </Grid>
        </motion.div>
    );
};
