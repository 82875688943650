import React, { useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { TopNavBar } from "./components/Includes/TopNavBar";
import { HomeTopNavBar } from "./components/Includes/HomeTopNavBar";
import { NavBar } from "./components/Includes/NavBar";
import { HomeNavBar } from "./components/Includes/HomeNavBar";
import { Home } from "./components/Home";
import { History } from "./components/History";
import { Shop } from "./components/Shop";
import { Presentation } from "./components/Presentation";
import { Footer } from "./components/Includes/Footer";
import { NotFound } from "./components/NotFound";
import { Aquaculture } from "./components/Products/Aquaculture";
import { Agriculture } from "./components/Products/Agriculture";
import { Pets } from "./components/Products/Pets";
import { Poultry } from "./components/Products/Poultry";
import { Contact } from "./components/Products/Contact";
import { Login } from "./components/auth/Login";
import { Reset } from "./components/auth/Reset";
import { Dashboard } from "./components/auth/Dashboard";
import { myContext } from "./components/context";

const theme = createTheme({
    typography: {
        fontFamily: [
            "Poppins",
            "Dancing Script",
            "Lato",
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            "sans-serif",
        ].join(","),
    },
    status: {
        danger: "#6A378B",
    },
    palette: {
        primary: {
            main: "#3FB7BD",
        },
        secondary: {
            main: "#CF2560",
        },
        tertiary: {
            main: "#2AA354",
            // darker: "#37878B",
        },
    },
});

function App() {
    const location = useLocation();
    console.log("loc", location);
    const ctx = useContext(myContext);
    console.log("ctx", ctx);
    return (
        <ThemeProvider theme={theme}>
            {location.pathname === "/" ? <HomeTopNavBar /> : <TopNavBar />}
            {location.pathname === "/" ? <HomeNavBar /> : <NavBar />}
            <AnimatePresence exitBeforeEnter>
                <Routes location={location} key={location.key}>
                    <Route path="/" element={<Home />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/presentation" element={<Presentation />} />
                    <Route
                        path="/products/aquaculture"
                        element={<Aquaculture />}
                    />
                    <Route
                        path="/products/agriculture"
                        element={<Agriculture />}
                    />
                    <Route path="/products/pets" element={<Pets />} />
                    <Route path="/products/poultry" element={<Poultry />} />
                    <Route path="/products/contact" element={<Contact />} />
                    <Route path="/shop" element={<Shop />} />
                    {ctx ? (
                        <Route path="/dashboard" element={<Dashboard />} />
                    ) : (
                        <>
                            <Route path="/login" element={<Login />} />
                            <Route path="/reset" element={<Reset />} />
                        </>
                    )}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </AnimatePresence>
            <Footer />
        </ThemeProvider>
    );
}

export default App;
