import {
    Stack,
    Box,
    Typography,
    Link,
    IconButton,
    Divider,
} from "@mui/material";
import Image from "../../assets/Logo.png";
import { CSSProperties } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    container: {
        padding: "4%",
    },
    ContentContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        textAlign: "justify",
        textJustify: "inter-word",
    },
    copyrights: {
        textAlign: "center",
    },
    image: {
        maxHeight: "100px",
    },
};

const boxVariant = {
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 0.3, duration: 1 },
    },
    hidden: { opacity: 0, y: 100 },
};

export const Footer = () => {
    const { t } = useTranslation();
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        }
    }, [control, inView]);
    return (
        <Stack style={styles.container} spacing={2}>
            <Divider sx={{ marginBottom: "2%" }} />
            <motion.div
                ref={ref}
                variants={boxVariant}
                initial="hidden"
                animate={control}
            >
                <Stack
                    direction={{ xs: "column", sm: "column", md: "row" }}
                    spacing={2}
                    style={styles.ContentContainer}
                >
                    <img style={styles.image} src={Image} alt="Content" />
                    <Stack direction="row" spacing={3}>
                        <Box>
                            <Typography variant="h6">&nbsp;Contact</Typography>
                            <Typography variant="body2">
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    href="tel:+14383573515"
                                    target="_blank"
                                >
                                    <IconButton
                                        aria-label="tele"
                                        size="medium"
                                        sx={{
                                            borderRadius: "15px",
                                            color: "black",
                                            ":hover": {
                                                backgroundColor: "transparent",
                                                color: "#444",
                                                transition: "color 0.2s",
                                            },
                                        }}
                                    >
                                        <LocalPhoneIcon fontSize="medium" />
                                    </IconButton>
                                    +1 (438) 357-3515
                                </Link>
                                <br />
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    href="https://api.whatsapp.com/send?phone=212663808980&text=Bonjour,%20et%20que%20je%20peux%20vous%20aider%20"
                                    target="_blank"
                                >
                                    <IconButton
                                        aria-label="whatsapp"
                                        size="medium"
                                        sx={{
                                            borderRadius: "15px",
                                            color: "black",
                                            ":hover": {
                                                backgroundColor: "transparent",
                                                color: "#25D366",
                                                transition: "color 0.2s",
                                            },
                                        }}
                                    >
                                        <WhatsAppIcon fontSize="medium" />
                                    </IconButton>
                                    +212 (663) 808-980
                                </Link>
                                <br />
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    href="tel:+212 666 955 337"
                                    target="_blank"
                                >
                                    <IconButton
                                        aria-label="tele"
                                        size="medium"
                                        sx={{
                                            borderRadius: "15px",
                                            color: "black",
                                            ":hover": {
                                                backgroundColor: "transparent",
                                                color: "#444",
                                                transition: "color 0.2s",
                                            },
                                        }}
                                    >
                                        <LocalPhoneIcon fontSize="medium" />
                                    </IconButton>
                                    +212 (666) 955-337
                                </Link>
                                <br />
                                <b
                                    style={{
                                        marginLeft: "10px",
                                    }}
                                >
                                    Media & Business requests:
                                </b>
                                <br />
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                        marginLeft: "10px",
                                    }}
                                    href="mailto:info@entomonutris.com"
                                >
                                    info@entomonutris.com
                                </Link>
                            </Typography>
                            <Link
                                href="https://www.linkedin.com/company/entomonutris/"
                                target="_blank"
                            >
                                <IconButton
                                    aria-label="linkedin"
                                    size="medium"
                                    sx={{
                                        borderRadius: "15px",
                                        color: "black",
                                        ":hover": {
                                            backgroundColor: "transparent",
                                            color: "#0072b1",
                                            transition: "color 0.2s",
                                        },
                                    }}
                                >
                                    <LinkedInIcon fontSize="medium" />
                                </IconButton>
                            </Link>
                            <Link
                                href="https://twitter.com/entomonutris"
                                target="_blank"
                            >
                                <IconButton
                                    aria-label="twitter"
                                    size="medium"
                                    sx={{
                                        borderRadius: "15px",
                                        color: "black",
                                        ":hover": {
                                            backgroundColor: "transparent",
                                            color: "#1DA1F2",
                                            transition: "color 0.2s",
                                        },
                                    }}
                                >
                                    <TwitterIcon fontSize="medium" />
                                </IconButton>
                            </Link>
                            <Link
                                href="https://www.instagram.com/entomonutris/"
                                target="_blank"
                            >
                                <IconButton
                                    aria-label="instagram"
                                    size="medium"
                                    sx={{
                                        borderRadius: "15px",
                                        color: "black",
                                        ":hover": {
                                            backgroundColor: "transparent",
                                            color: "#d6249f",
                                            transition: "color 0.2s",
                                        },
                                    }}
                                >
                                    <InstagramIcon fontSize="medium" />
                                </IconButton>
                            </Link>
                            <Link
                                href="https://www.facebook.com/Entomonutris/"
                                target="_blank"
                            >
                                <IconButton
                                    aria-label="facebook"
                                    size="medium"
                                    sx={{
                                        borderRadius: "15px",
                                        color: "black",
                                        ":hover": {
                                            backgroundColor: "transparent",
                                            color: "#4267B2",
                                            transition: "color 0.2s",
                                        },
                                    }}
                                >
                                    <FacebookIcon fontSize="medium" />
                                </IconButton>
                            </Link>
                            <Link
                                href="https://api.whatsapp.com/send?phone=212663808980&text=Bonjour,%20et%20que%20je%20peux%20vous%20aider%20"
                                target="_blank"
                            >
                                <IconButton
                                    aria-label="whatsapp"
                                    size="medium"
                                    sx={{
                                        borderRadius: "15px",
                                        color: "black",
                                        ":hover": {
                                            backgroundColor: "transparent",
                                            color: "#25D366",
                                            transition: "color 0.2s",
                                        },
                                    }}
                                >
                                    <WhatsAppIcon fontSize="medium" />
                                </IconButton>
                            </Link>
                            <Link href="#" target="_blank">
                                <IconButton
                                    aria-label="youtube"
                                    size="medium"
                                    sx={{
                                        borderRadius: "15px",
                                        color: "black",
                                        ":hover": {
                                            backgroundColor: "transparent",
                                            color: "#FF0000",
                                            transition: "color 0.2s",
                                        },
                                    }}
                                >
                                    <YouTubeIcon fontSize="medium" />
                                </IconButton>
                            </Link>
                        </Box>
                        <Box style={{ maxWidth: "220px" }}>
                            <Typography variant="h6">{t("adress")}</Typography>
                            <Typography variant="body2">
                                <b>Maroc Office:</b>
                                <br />
                                Bureau 2 CITÉ DE L'INNOVATION UCA 112 Boulevard
                                Abdelkrim Al Khattabi, Gueliz, Marrakech 40000
                            </Typography>
                            <Typography
                                style={{ marginTop: "10px" }}
                                variant="body2"
                            >
                                <b>Canada Office:</b>
                                <br /> 93 saint louis, Longueuil J4R 2L3
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
                <Typography variant="body2" style={styles.copyrights}>
                    Copyright &copy; 2022 by EntomoNutris. All rights reserved.
                </Typography>
            </motion.div>
        </Stack>
    );
};
