import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";

import { Context } from "./components/context";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        supportedLngs: ["en", "fr"],
        fallbackLng: "fr",
        debug: false,
        detection: {
            order: [
                "path",
                "cookie",
                "htmlTag",
                "localStorage",
                "subdomain",
                "querystring",
                "sessionStorage",
                "navigator",
            ],
            caches: ["cookie"],
        },
        backend: {
            loadPath: "/assets/locales/{{lng}}/translation.json",
        },
    });

// const Loading = () => {
//     return <div></div>;
// };

// ReactDOM.render(
//     <Suspense fallback={Loading}>
//         <React.StrictMode>
//             <Router>
//                 <App />
//             </Router>
//         </React.StrictMode>
//     </Suspense>,
//     document.getElementById("root")
// );

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
const Loading: any = () => {
    return <div></div>;
};
root.render(
    <Suspense fallback={Loading}>
        <React.StrictMode>
            <Context>
                <Router>
                    <App />
                </Router>
            </Context>
        </React.StrictMode>
    </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
