import {
    AppBar,
    Toolbar,
    Stack,
    Button,
    Menu,
    MenuItem,
    Typography,
    IconButton,
    Box,
    ListItemIcon,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MenuIcon from "@mui/icons-material/Menu";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import PetsIcon from "@mui/icons-material/Pets";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import SetMealIcon from "@mui/icons-material/SetMeal";
import { AnimatedLogo } from "./AnimatedLogo";
// import HomeIcon from "@mui/icons-material/Home";
import { useState, useEffect } from "react";
import Logo from "../../assets/Logo.png";
import Chicken from "../../assets/chicken.png";
// import whiteLogo from "../../assets/white logo.png";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "react";
interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    navBar: {
        backgroundColor: "transparent",
        marginTop: "34px",
    },
    hideNavBar: {
        display: "none",
        opacity: "0",
        transition: "opacity 0.5s ease-in, display 1s ease-in 1s",
    },
    navMenu: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    logo: {
        height: "70px",
        overflow: "visible",
        stroke: "#fff",
        strokeWidth: "4",
        strokeLinejoin: "round",
        strokeLinecap: "round",
    },
    btn: {
        borderRadius: "0px",
        paddingBottom: "-5px",
    },
    menu: {
        display: "inline",
        backgroundColor: "transparent",
        color: "red",
    },
};

const navBarVariants = {
    hidden: {
        y: -250,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { delay: 0.2, type: "spring", stiffness: 120 },
    },
};

export const HomeNavBar = () => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState<string | false>(false);
    const handleChange = (isExpanded: boolean, panel: string) => {
        setExpanded(isExpanded ? panel : false);
    };
    const [anchorElProducts, setAnchorElProducts] =
        useState<null | HTMLElement>(null);
    const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);

    const openMenu = Boolean(anchorElMenu);
    const openProducts = Boolean(anchorElProducts);
    const handleClickProducts = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        if (anchorElProducts !== event.currentTarget) {
            setAnchorElProducts(event.currentTarget);
        }
    };
    const handleCloseProducts = () => {
        setAnchorElProducts(null);
    };
    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorElMenu !== event.currentTarget) {
            setAnchorElMenu(event.currentTarget);
        }
    };
    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };
    // const handleMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     console.log("mouse enter", event);
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleMouseLeave = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setTimeout(() => {
    //     setAnchorEl(null);
    //     }, 1000);
    // };
    const [show, setShow] = useState(true);
    const [changeNavBar, setChangeNavBar] = useState(false);
    let lastScrollY = window.scrollY;
    const controlNavbar = () => {
        if (window.scrollY < window.innerHeight - 94) {
            setShow(true);
            setChangeNavBar(false);
        } else if (lastScrollY > window.scrollY) {
            setShow(true);
            setChangeNavBar(true);
        } else {
            setShow(false);
            setChangeNavBar(false);
        }
        lastScrollY = window.scrollY;
    };
    useEffect(() => {
        window.addEventListener("scroll", controlNavbar);
        return () => {
            window.removeEventListener("scroll", controlNavbar);
        };
    });
    return (
        <motion.div
            variants={navBarVariants}
            initial="hidden"
            animate="visible"
        >
            <AppBar
                style={
                    show
                        ? { opacity: "1", transition: "opacity 0.5s ease-in" }
                        : styles.hideNavBar
                }
                elevation={0}
                sx={{
                    backgroundColor: `${
                        changeNavBar ? "white" : "transparent"
                    }`,
                    marginTop: `${changeNavBar ? "0px" : "34px"}`,
                }}
            >
                <Toolbar>
                    <Link to="/">
                        <Button
                            disableRipple
                            sx={{
                                ":hover": {
                                    backgroundColor: "transparent",
                                },
                            }}
                        >
                            <span
                                style={
                                    changeNavBar
                                        ? {
                                              opacity: "0",
                                              display: "none",
                                              transition:
                                                  "opacity 0.5s ease-in",
                                          }
                                        : {
                                              opacity: "1",
                                              transition:
                                                  "opacity 0.5s ease-in",
                                          }
                                }
                            >
                                <AnimatedLogo />
                            </span>
                            <span
                                style={
                                    changeNavBar
                                        ? {
                                              opacity: "1",
                                              transition:
                                                  "opacity 0.5s ease-in",
                                          }
                                        : {
                                              opacity: "0",
                                              display: "none",
                                              transition:
                                                  "opacity 0.5s ease-in",
                                          }
                                }
                            >
                                <img
                                    src={Logo}
                                    alt="Logo"
                                    style={styles.logo}
                                />
                            </span>
                        </Button>
                    </Link>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}></Typography>
                    <Box
                        sx={{
                            flexGrow: 0,
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                            style={styles.navMenu}
                        >
                            <Link to="/" style={{ textDecoration: "none" }}>
                                <motion.div
                                    whileHover={{
                                        scale: 1.1,
                                    }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 300,
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        variant="text"
                                        style={styles.btn}
                                        disableRipple
                                        sx={{
                                            height: "50%",
                                            color: `${
                                                changeNavBar ? "black" : "white"
                                            }`,
                                            ":hover": {
                                                backgroundColor: "transparent",
                                                borderBottom: `2px solid ${
                                                    changeNavBar
                                                        ? "black"
                                                        : "white"
                                                }`,
                                                transition:
                                                    "border-bottom 0.2s",
                                            },
                                        }}
                                    >
                                        {t("navBar.home")}
                                    </Button>
                                </motion.div>
                            </Link>
                            <Link
                                to="/presentation"
                                style={{ textDecoration: "none" }}
                            >
                                <motion.div
                                    whileHover={{
                                        scale: 1.1,
                                    }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 300,
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        variant="text"
                                        style={styles.btn}
                                        disableRipple
                                        sx={{
                                            height: "50%",
                                            color: `${
                                                changeNavBar ? "black" : "white"
                                            }`,
                                            ":hover": {
                                                backgroundColor: "transparent",
                                                borderBottom: `2px solid ${
                                                    changeNavBar
                                                        ? "black"
                                                        : "white"
                                                }`,
                                                transition:
                                                    "border-bottom 0.2s",
                                            },
                                        }}
                                    >
                                        {t("navBar.presentation")}
                                    </Button>
                                </motion.div>
                            </Link>
                            <Link
                                to="/history"
                                style={{ textDecoration: "none" }}
                            >
                                <motion.div
                                    whileHover={{
                                        scale: 1.1,
                                    }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 300,
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        variant="text"
                                        style={styles.btn}
                                        disableRipple
                                        sx={{
                                            height: "50%",
                                            color: `${
                                                changeNavBar ? "black" : "white"
                                            }`,
                                            ":hover": {
                                                backgroundColor: "transparent",
                                                borderBottom: `2px solid ${
                                                    changeNavBar
                                                        ? "black"
                                                        : "white"
                                                }`,
                                                transition:
                                                    "border-bottom 0.2s",
                                            },
                                        }}
                                    >
                                        {t("navBar.history")}
                                    </Button>
                                </motion.div>
                            </Link>
                            <Button
                                color="primary"
                                variant="text"
                                id="products-button"
                                onMouseEnter={handleClickProducts}
                                onClick={handleClickProducts}
                                disableRipple
                                // onMouseLeave={handleMouseLeave}
                                aria-controls={
                                    openProducts ? "products-menu" : "false"
                                }
                                aria-haspopup="true"
                                aria-expanded={openProducts ? true : false}
                                endIcon={<KeyboardArrowDownIcon />}
                                style={styles.btn}
                                sx={{
                                    color: `${
                                        changeNavBar ? "black" : "white"
                                    }`,
                                    ":hover": {
                                        backgroundColor: "transparent",
                                    },
                                }}
                            >
                                {t("navBar.products")}
                            </Button>
                            <Link to="/shop" style={{ textDecoration: "none" }}>
                                <motion.div
                                    whileHover={{
                                        scale: 1.1,
                                    }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 300,
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        variant="text"
                                        disableRipple
                                        endIcon={<LocalGroceryStoreIcon />}
                                        style={styles.btn}
                                        sx={{
                                            color: `${
                                                changeNavBar ? "black" : "white"
                                            }`,
                                            height: "50%",
                                            ":hover": {
                                                backgroundColor: "transparent",
                                                borderBottom: `2px solid ${
                                                    changeNavBar
                                                        ? "black"
                                                        : "white"
                                                }`,
                                                transition:
                                                    "border-bottom 0.1s",
                                            },
                                        }}
                                    >
                                        {t("navBar.shop")}
                                    </Button>
                                </motion.div>
                            </Link>

                            <Link to="/products/contact">
                                <motion.div
                                    whileHover={{
                                        scale: 1.1,
                                    }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 300,
                                    }}
                                >
                                    <IconButton
                                        size="large"
                                        sx={{
                                            color: `${
                                                changeNavBar ? "black" : "white"
                                            }`,
                                            ":hover": {
                                                backgroundColor: "transparent",
                                            },
                                        }}
                                    >
                                        <MailOutlineIcon />
                                    </IconButton>
                                </motion.div>
                            </Link>
                        </Stack>
                        <Menu
                            id="products-menu"
                            anchorEl={anchorElProducts}
                            open={openProducts}
                            MenuListProps={{
                                "aria-labelledby": "products-button",
                                onMouseLeave: handleCloseProducts,
                            }}
                            onClose={handleCloseProducts}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <MenuItem onClick={handleCloseProducts}>
                                <ListItemIcon>
                                    <SetMealIcon
                                        fontSize="small"
                                        style={{
                                            color: "black",
                                        }}
                                    />
                                </ListItemIcon>
                                <Link
                                    to="/products/aquaculture"
                                    style={{
                                        color: "black",
                                        textDecoration: "none",
                                    }}
                                >
                                    <ListItemText>Aquaculture</ListItemText>
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleCloseProducts}>
                                <ListItemIcon>
                                    <AgricultureIcon
                                        fontSize="small"
                                        style={{
                                            color: "black",
                                        }}
                                    />
                                </ListItemIcon>
                                <Link
                                    to="/products/agriculture"
                                    style={{
                                        color: "black",
                                        textDecoration: "none",
                                    }}
                                >
                                    <ListItemText>Agriculture</ListItemText>
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleCloseProducts}>
                                <ListItemIcon>
                                    <PetsIcon
                                        fontSize="small"
                                        style={{
                                            color: "black",
                                        }}
                                    />
                                </ListItemIcon>
                                <Link
                                    to="/products/pets"
                                    style={{
                                        color: "black",
                                        textDecoration: "none",
                                    }}
                                >
                                    <ListItemText>
                                        {t("navBar.products.3")}
                                    </ListItemText>
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={handleCloseProducts}>
                                <ListItemIcon>
                                    <img src={Chicken} alt="chicken" />
                                </ListItemIcon>
                                <Link
                                    to="/products/poultry"
                                    style={{
                                        color: "black",
                                        textDecoration: "none",
                                    }}
                                >
                                    <ListItemText>
                                        {t("navBar.products.4")}
                                    </ListItemText>
                                </Link>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 0,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            onClick={handleClickMenu}
                            id="bar-button"
                            aria-label="account of current user"
                            aria-controls={openMenu ? "bar-menu" : "false"}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? true : false}
                            style={styles.btn}
                            sx={{
                                color: `${changeNavBar ? "black" : "white"}`,
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                            id="bar-menu"
                            anchorEl={anchorElMenu}
                            open={openMenu}
                            MenuListProps={{
                                "aria-labelledby": "bar-button",
                            }}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <Link
                                to="/"
                                style={{
                                    textDecoration: "none",
                                    color: "#212121",
                                }}
                            >
                                <MenuItem onClick={handleCloseMenu}>
                                    {t("navBar.home")}
                                </MenuItem>
                            </Link>
                            <Link
                                to="/presentation"
                                style={{
                                    textDecoration: "none",
                                    color: "#212121",
                                }}
                            >
                                <MenuItem onClick={handleCloseMenu}>
                                    {t("navBar.presentation")}
                                </MenuItem>
                            </Link>
                            <Link
                                to="history"
                                style={{
                                    textDecoration: "none",
                                    color: "#212121",
                                }}
                            >
                                <MenuItem onClick={handleCloseMenu}>
                                    {t("navBar.history")}
                                </MenuItem>
                            </Link>
                            <Accordion
                                expanded={expanded === "panel1"}
                                onChange={(event, isExpanded) =>
                                    handleChange(isExpanded, "panel1")
                                }
                                elevation={0}
                            >
                                <AccordionSummary
                                    id="panel1-header"
                                    aria-controls="panel1-content"
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography>
                                        {t("navBar.products")}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <MenuItem onClick={handleCloseMenu}>
                                        <ListItemIcon>
                                            <SetMealIcon
                                                fontSize="small"
                                                style={{
                                                    color: "black",
                                                }}
                                            />
                                        </ListItemIcon>
                                        <Link
                                            to="/products/aquaculture"
                                            style={{
                                                color: "black",
                                                textDecoration: "none",
                                            }}
                                        >
                                            <ListItemText>
                                                Aquaculture
                                            </ListItemText>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleCloseMenu}>
                                        <ListItemIcon>
                                            <AgricultureIcon
                                                fontSize="small"
                                                style={{
                                                    color: "black",
                                                }}
                                            />
                                        </ListItemIcon>
                                        <Link
                                            to="/products/agriculture"
                                            style={{
                                                color: "black",
                                                textDecoration: "none",
                                            }}
                                        >
                                            <ListItemText>
                                                Agriculture
                                            </ListItemText>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleCloseMenu}>
                                        <ListItemIcon>
                                            <PetsIcon
                                                fontSize="small"
                                                style={{
                                                    color: "black",
                                                }}
                                            />
                                        </ListItemIcon>
                                        <Link
                                            to="/products/pets"
                                            style={{
                                                color: "black",
                                                textDecoration: "none",
                                            }}
                                        >
                                            <ListItemText>
                                                {t("navBar.products.3")}
                                            </ListItemText>
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleCloseMenu}>
                                        <ListItemIcon>
                                            <img src={Chicken} alt="chicken" />
                                        </ListItemIcon>
                                        <Link
                                            to="/products/poultry"
                                            style={{
                                                color: "black",
                                                textDecoration: "none",
                                            }}
                                        >
                                            <ListItemText>
                                                {t("navBar.products.4")}
                                            </ListItemText>
                                        </Link>
                                    </MenuItem>
                                </AccordionDetails>
                            </Accordion>
                            <Link
                                to="/shop"
                                style={{
                                    textDecoration: "none",
                                    color: "#212121",
                                }}
                            >
                                <MenuItem onClick={handleCloseMenu}>
                                    {t("navBar.shop")}
                                </MenuItem>
                            </Link>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
        </motion.div>
    );
};
