import React from "react";
import { motion } from "framer-motion";
import { CSSProperties } from "react";

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    container: {
        padding: "25vh 4% 0%",
        display: "flex",
        backgroundColor: "#eee",
        color: "#121212",
        height: "75vh  ",
        justifyContent: "center",
        alignItems: "center",
    },
};

const shopVariants = {
    hidden: {
        x: "100vw",
    },
    visible: {
        x: 0,
        transition: { type: "spring" },
    },
    exit: {
        x: "-100vw",
        transition: { ease: "easeInOut" },
    },
};

export const Shop = () => {
    return (
        <motion.div
            variants={shopVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            style={styles.container}
        >
            <h1>Coming Soon...</h1>
        </motion.div>
    );
};
