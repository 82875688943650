import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { CSSProperties, useEffect, useState } from "react";

interface StylesDictionary {
    [Key: string]: CSSProperties;
}

const styles: StylesDictionary = {
    container: {
        paddingTop: "20vh",
    },
};

type Order = {
    _id: string;
    name: string;
    email: string;
    phone: string;
    order: string;
    status: string;
    username?: string;
};

export const Dashboard = () => {
    const [data, setData] = useState<Order[]>([]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_MY_API}dashboard`, {
                withCredentials: true,
            })
            .then((response: AxiosResponse) => {
                if (response.status === 200) {
                    setData(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    return (
        <TableContainer
            // component={Paper}
            sx={{ maxHeight: "600px" }}
            style={styles.container}
        >
            <Table aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell align="center">Demande</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">UserName</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row: Order) => (
                        <TableRow
                            key={row._id}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.phone}</TableCell>
                            <TableCell align="center">{row.order}</TableCell>
                            <TableCell align="center">{row.status}</TableCell>
                            <TableCell align="center">{row.username}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
