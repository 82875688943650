import { useState } from "react";
import { Link } from "react-router-dom";
import { Stack, TextField, Alert } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

export const Login = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string[]>([]);

    let errors: string[] = [];
    const onSubmit = async (e: any) => {
        e.preventDefault();
        if (!email) {
            errors.push("Email is required");
        }
        if (!password) {
            errors.push("Password is required");
        }
        if (errors.length > 0) {
            setError(errors);
            return;
        }
        await axios
            .post(
                `${process.env.REACT_APP_MY_API}auth/login`,
                {
                    email,
                    password,
                },
                { withCredentials: true }
            )
            .then((res: AxiosResponse) => {
                if (res.status === 200) {
                    setEmail("");
                    setPassword("");
                    window.location.href = "/dashboard";
                }
            })
            .catch((err: any) => {
                if (err.response.status === 422) {
                    for (
                        let i = 0;
                        i < err.response.data.message.errors.length;
                        i++
                    ) {
                        errors.push(err.response.data.message.errors[i].msg);
                    }
                    return setError(errors);
                }
                if (err.response.status === 401) {
                    return setError(["Invalid email or password"]);
                }
                if (err.response.status === 403) {
                    return setError(["Account is not activated"]);
                }
                if (err.response.status === 500) {
                    return setError(["Internal Server Error"]);
                }
            });
    };
    return (
        <section className="section form__container container grid">
            <div className="form">
                <h2 className="form__title">{t("navBar.login")}</h2>
                <span className="form__subtitle">
                    {t("login.subtitle")} ?{" "}
                    <Link to="/signup">{t("login.subtitle2")}</Link>
                </span>
                <form onSubmit={onSubmit}>
                    <Stack spacing={2} direction="column">
                        {error.length > 0 ? (
                            <Alert
                                severity="error"
                                onClose={() => {
                                    setError([]);
                                }}
                            >
                                {error.map((err: string) => {
                                    return <div key="{err}">{err}</div>;
                                })}
                            </Alert>
                        ) : null}

                        <TextField
                            label="Email"
                            type="email"
                            size="small"
                            color="primary"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            label={t("login.password")}
                            type="password"
                            size="small"
                            color="primary"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button type="submit" className="button form__button">
                            {t("navBar.login")}
                        </button>
                    </Stack>
                </form>
                <span className="form__subtitle">
                    {t("login.forgot")} ?{" "}
                    <Link to="/reset">{t("login.reset")}</Link>
                </span>
                <div className="divider"></div>
            </div>
        </section>
    );
};
