import React from "react";
// import { motion } from "framer-motion";
import { HeroArea } from "./HeroArea";
import { Definition } from "./Definition";
import { HighlightedContent } from "./HighlightedContent";
import { Posts } from "./Posts";
import { Video } from "./Video";

// const homeVariants = {
//     hidden: {
//         x: "100vw",
//     },
//     visible: {
//         x: 0,
//         transition: { type: "spring" },
//     },
//     exit: {
//         x: "-100vw",
//         transition: { ease: "easeInOut" },
//     },
// };

export const Home = () => {
    return (
        // <motion.div
        //     variants={homeVariants}
        //     initial="hidden"
        //     animate="visible"
        //     exit="exit"
        // >
        <div>
            <HeroArea />
            <Definition />
            <HighlightedContent />
            <Posts />
            <Video />
        </div>
        // </motion.div>
    );
};
